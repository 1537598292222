
<div class="main">
    <section class="signup">
        <div class="container-fluid pl-0">
            <div class="row ">
                <div class="col-lg-6 col-md-6 d-md-block d-none">
                    <div class="left-view-slider">
                        <div class="view_carousel">
                            <owl-carousel-o [options]="customOptions">
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Multi-asset marketplace. </h2>
                                        <p>Trade multiple alternative assets in the luxury commodity and collectibles category.</p>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup-asset.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Advanced dashboard.</h2>
                                        <p>Keep track of your positions and activity with our multipurpose Dashboard.</p>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup-lend.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Lend and borrow against your assets</h2>
                                        <p>Leverage your position or gain yield lending to other users.</p>
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="right-view-form">
                        <div class="col-12 right-view-scroll">
                            <div class="col-lg-12">
                                <div class="authheader">
                                    <div class="d-flex justify-content-center">
                                        <div><a [routerLink]="['/']"><img src="assets/images/logo.png" width="250px" class="img-fluid"></a></div>
                                    </div>
                                </div>
                            </div>
                            <form [formGroup]="registerForm" class="col-lg-12 mb-3" (ngSubmit)="onRegister()">
                                <div class="my-4 position-relative">
                                    <hr>
                                    <p class="orposition">Register with.</p>
                                </div>
                                <div class="d-flex align-items-center justify-content-center gap-3 gap-sm-4 flex-wrap mb-3" *ngIf="regulated">
                                    <app-google-signin (loginWithGoogle)="googleSignin($event)"></app-google-signin>
                                    <a class="signapple cursor-pointer" (click)="appleSignin()"><i class="bi bi-apple"></i>Apple.</a>
                                </div>
                                <div class="my-4 position-relative" *ngIf="regulated">
                                    <hr>
                                    <p class="orposition">Or insert your information below.</p>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="firstname">First name.</label>
                                    <input type="text" class="form-control" id="firstname" placeholder="John Abraham." formControlName="name">
                                    <div class="input--error" *ngIf="(registerFormControls['name'].touched || registerFormSubmitted) && registerFormControls['name'].errors?.['required']">
                                        Name is required.*
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="email">Email address.</label>
                                    <input type="email" class="form-control" id="email" placeholder="Ex: johnabraham@mail.com." formControlName="email" >
                                    <div class="input--error" *ngIf="(registerFormControls['email'].touched || registerFormSubmitted) && registerFormControls['email'].errors?.['required']">
                                        Email is required.*
                                    </div>
                                    <div class="input--error" *ngIf="(registerFormControls['email'].touched || registerFormSubmitted) && registerFormControls['email'].errors?.['email']">
                                        Enter valid email address.*
                                    </div>
                                    <div class="input--error" *ngIf="(registerFormControls['email'].touched || registerFormSubmitted) && registerFormControls['email'].errors?.['forbidUppercase']">
                                        Capital letters not allowed.*
                                    </div>
                                </div>
                                <div class="form-group mb-3 " *ngIf="regulated">
                                    <label for="password">Password.</label>
                                    <div class="position-relative">
                                        <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" id="password" placeholder="Enter password." formControlName="password">
                                        <div class="icon_pos" (click)="toggleFieldTextType()">
                                            <i
                                            class="bi"
                                            [ngClass]="{
                                            'bi-eye-slash': fieldTextType,
                                            'bi-eye': !fieldTextType
                                            }"

                                        ></i>
                                        </div>
                                        <div class="input--error" *ngIf="(registerFormControls['password'].touched || registerFormSubmitted) && registerFormControls['password'].errors?.['required']">
                                            Password is required.*
                                        </div>

                                    </div>
                                    <div class="" *ngIf="(registerFormControls['password'].dirty || registerFormSubmitted) && !registerFormControls['password'].hasError('required') && registerFormControls['password']?.errors">
                                        <ul>
                                            <ng-container *ngFor="let item of passwordValidations">
                                                    <li [ngClass]="isValid(item.validation) ? 'input--error-green' : 'input--error'">{{item?.message}}</li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form-group mb-3" *ngIf="regulated">
                                    <label for="cpassword">Re enter password.</label>
                                    <div class="position-relative">
                                        <input [type]="fieldTextTypeCp ? 'text' : 'password'" class="form-control" id="password" placeholder="Re enter password." formControlName="confirmPassword">
                                        <div class="icon_pos" (click)="toggleFieldTextTypeTwo()">
                                            <i
                                            class="bi"
                                            [ngClass]="{
                                            'bi-eye-slash': fieldTextTypeCp,
                                            'bi-eye': !fieldTextTypeCp
                                            }"

                                        ></i>
                                        </div>
                                    </div>
                                    <div class="input--error" *ngIf="(registerFormControls['confirmPassword'].touched || registerFormSubmitted) && registerFormControls['confirmPassword'].errors?.['required']">
                                        Confirm Password is required. *
                                    </div>
                                    <div class="input--error" *ngIf="(registerFormSubmitted) && !registerFormControls['confirmPassword'].errors?.['required'] && registerForm.errors?.['passwordNotSame']">
                                        Password and confirm password is not same.*
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label><input type="checkbox" formControlName="is_policy_accepted"><span class="pl-2">I agree to the <a href="{{privacyPolicyUrl}}" target="_blank">privacy policy</a> and <a href="{{userAggrementUrl}}" target="_blank">terms of service</a>.</span></label>
                                    <div class="input--error" *ngIf="(registerFormSubmitted) && registerFormControls['is_policy_accepted'].errors?.['required']">
                                        Accept to continue.
                                    </div>
                                </div>
                                <div class="gap-4 mb-2">
                                    <button type="submit" class="wallet-connect" appDebounceClick (debounceClick)="onRegister()" [disabled]="registerFormProcessing" [ngClass]="{'disabled': registerFormProcessing}">Register.</button>
                                </div>

                                <div class="text-center" *ngIf="regulated"><span>Already have an account? </span><a class="pl-2" [routerLink]="['/sign-in']">Login.</a></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
