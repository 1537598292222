<div class="main">
    <section class="signup">
        <div class="container-fluid pl-0">
            <div class="row align-items-lg-center">
                <div class="col-lg-6 col-md-6 d-md-block d-none">
                    <div class="left-view-slider">
                        <div class="view_carousel">
                            <owl-carousel-o [options]="customOptions">
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Multi-asset marketplace. </h2>
                                        <p>Trade multiple alternative assets in the luxury commodity and collectibles
                                            category.</p>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup-asset.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Advanced dashboard.</h2>
                                        <p>Keep track of your positions and activity with our multipurpose Dashboard.
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <img src="assets/images/signup-lend.png" alt="img" class="img-fluid">
                                    <div class="slidetext">
                                        <h2>Lend and borrow against your assets</h2>
                                        <p>Leverage your position or gain yield lending to other users.</p>
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 px-4">
                    <div class="right-view-form" *ngIf="!loader && userId">
                        <div class="col-lg-12">
                            <div class="authheader">
                                <div class="d-flex justify-content-center">
                                    <div><a [routerLink]="['/']" (click)="routingHome()"><img
                                                src="assets/images/logo.png" width="250px" class="img-fluid"></a></div>
                                </div>
                            </div>
                        </div>
                        <form class="col-lg-12 mb-3" [formGroup]="resetPasswordForm">
                            <h4>Reset your password.</h4>
                            <p>Enter the new password for the email address associated with <a>{{email}}</a>.</p>
                            <div class="form-group mb-3">
                                <label for="password">Enter password.</label>
                                <div class="position-relative">
                                    <input [type]="showpassword?'text':'password' " class="form-control" id="password"
                                        placeholder="Enter password." formControlName="password">
                                    <div class="password_pos">
                                        <i [ngClass]="{'bi bi-eye':showpassword, 'bi bi-eye-slash':!showpassword}"
                                            (click)="passwordView()"></i>
                                    </div>
                                    <div class="input--error"
                                        *ngIf="resetPasswordFormSubmitted && resetPasswordFormControls['password'].errors?.['required']">
                                        Password is required.*
                                    </div>
                                </div>
                                <div class="input--error"
                                    *ngIf="(resetPasswordFormControls['password'].touched || resetPasswordFormSubmitted) && !resetPasswordFormControls['password'].errors?.['required'] && resetPasswordFormControls['password'].errors?.['pattern']">
                                    <ul>
                                        <li>At least one uppercase letter.</li>
                                        <li>At least one lowercase letter.</li>
                                        <li>At least one digit.</li>
                                        <li>At least one special character.</li>
                                        <li>At least a minimum of 12 characters.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label for="cpassword">Re Enter password.</label>
                                <div class="position-relative">
                                    <input [type]="showcpassword?'text':'password' " class="form-control" id="cpassword"
                                        placeholder="Re Enter password." formControlName="confirmPassword">
                                    <div class="password_pos">
                                        <i [ngClass]="{'bi bi-eye':showcpassword, 'bi bi-eye-slash':!showcpassword}"
                                            (click)="passwordConfirmView()"></i>
                                    </div>
                                    <div class="input--error"
                                        *ngIf="resetPasswordFormSubmitted && resetPasswordFormControls['confirmPassword'].errors?.['required']">
                                        Confirm Password is required.*
                                    </div>
                                    <div class="input--error"
                                        *ngIf="resetPasswordFormSubmitted && !resetPasswordFormControls['confirmPassword'].errors?.['required'] && resetPasswordForm.errors?.['passwordNotSame']">
                                        Password and confirm password is not same.*
                                    </div>
                                </div>
                            </div>
                            <div class="gap-4 mb-3">
                                <button class="wallet-connect"  type="submit" appDebounceClick (debounceClick)="resetPassword()" [disabled]="resetPasswordFormProcessing" [ngClass]="{'disabled': resetPasswordFormProcessing}">Proceed.</button>
                            </div>
                        </form>
                    </div>
                    <div class="right-view-form" *ngIf="loader">
                        <div class="d-flex align-items-center justify-content-center flex-wrap">
                            <h6>Please wait while we validate link.</h6>
                            <span class="Loaders"></span>
                        </div>
                    </div>
                    <div class="right-view-form" *ngIf="!loader && !userId">
                        <div class="linkexpired text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#ff0000"
                                class="bi bi-x-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                            <div class="my-4">
                                <h6 class="mb-2">Reset password link has expired.</h6>
                                <h6>Get a new link by trying <a style="color:#006736;text-decoration: underline;"
                                        [routerLink]="['/forgot-password']">forgot password</a> again. </h6>
                            </div>
                            <a class="onboard" [routerLink]="['/sign-in']">Return to Signin.</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>