<div class="main-wrap" (click)="closeOverlay()">
    <app-sidebar class="sidebarview"></app-sidebar>
    <app-contract-transactions></app-contract-transactions>
    <section class="section">
        <div class="container-fluid">
            <div class="row pb-3 align-items-center justify-content-between" *ngIf="account">
                <div class="col-lg-9 col-sm-7 col-12 mb-3 sm-mb-0">
                    <ul class="borrow-submenus">
                        <li class="nav-item" (click)="onClickingBorrow()" [routerLink]="['/loan-request']">New loan
                            request.</li>
                        <li class="nav-item " [routerLink]="['/borrow']">Live borrowing.</li>
                        <li class="nav-item" (click)="onClickingBorrow()" routerLinkActive="menuactivities"
                            [routerLink]="['/borrow-history']" [routerLinkActiveOptions]="{exact: true}">Borrowing
                            history.</li>
                    </ul>
                </div>
                <div class="col-sm-5 col-lg-3 col-12">
                    <div class="dropdown">
                        <div class="dropdown">
                            <select class='select-option btn btn-sort' (change)='sort($event)'>
                                <option class='option' value="">Filter by.</option>
                                <option class='option' value="repayed-loans">Repaid.</option>
                                <option class='option' value="closed-loans">Foreclosed.</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <!--grid view-->
            <div class="gridview" *ngIf="account && isGridView && !loader">
                <div class="row row-cols-1 row-cols-sm-2  row-cols-lg-4 row-cols-xl-5">
                    <div class="col mb-4" *ngFor="let request of borrowingHistory;let i = index;">
                        <a [routerLink]="['/borrow-detail', request._id]" [queryParams]="{regulated}">
                            <div class="nft-wrap"
                                [ngClass]="{'repayed': request.status === 2 ,'foreclosed': request.status === 3}">
                                <div *ngIf="request.collateral_assets.length === 1">
                                    <div class="img-view mb-2" *ngFor="let asset of request.collateral_assets">
                                        <img *ngIf="asset?.fileType != 'html'"
                                            [src]="asset?.preview_image ? asset?.preview_image : asset.primary_media"
                                            onerror="this.src='assets/images/default-nft.svg'"
                                            (load)="imageLoading = false" class="img-fluid">
                                        <div class="gemsView" *ngIf="asset?.fileType === 'html'">
                                            <img [src]="asset?.preview_image ? asset?.preview_image : asset?.secondary_media[0] "
                                                onerror="this.src='assets/images/default-nft.svg'"
                                                (load)="imageLoading = false" alt="nft" class="img-fluid tableviewnfts">
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="request.collateral_assets.length === 2">
                                    <div class="twoimages mb-2">
                                        <div *ngFor="let asset of request.collateral_assets">
                                            <img *ngIf="asset?.fileType != 'html'"
                                                [src]="asset?.preview_image ? asset?.preview_image : asset.primary_media"
                                                onerror="this.src='assets/images/default-nft.svg'"
                                                (load)="imageLoading = false" class="img-fluid">
                                            <div class="gemsView" *ngIf="asset?.fileType === 'html'">
                                                <img [src]="asset?.preview_image ? asset?.preview_image : asset?.secondary_media[0]"
                                                    onerror="this.src='assets/images/default-nft.svg'"
                                                    (load)="imageLoading = false" alt="nft"
                                                    class="img-fluid tableviewnfts">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="request.collateral_assets.length === 3">
                                    <div class="third_img mb-2">
                                        <div *ngFor="let asset of request.collateral_assets">
                                            <img *ngIf="asset?.fileType != 'html'"
                                                [src]="asset?.preview_image ? asset?.preview_image : asset.primary_media"
                                                onerror="this.src='assets/images/default-nft.svg'"
                                                (load)="imageLoading = false" class="img-fluid">
                                            <div class="gemsView" *ngIf="asset?.fileType === 'html'">
                                                <img [src]="asset?.preview_image ? asset?.preview_image : asset?.secondary_media[0]"
                                                    onerror="this.src='assets/images/default-nft.svg'"
                                                    (load)="imageLoading = false" alt="nft"
                                                    class="img-fluid tableviewnfts">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="request.collateral_assets.length === 4">
                                    <div class="third_img mb-2">
                                        <div *ngFor="let asset of request.collateral_assets">
                                            <img *ngIf="asset?.fileType != 'html'"
                                                [src]="asset?.preview_image ? asset?.preview_image : asset.primary_media"
                                                onerror="this.src='assets/images/default-nft.svg'"
                                                (load)="imageLoading = false" class="img-fluid">
                                            <div class="gemsView" *ngIf="asset?.fileType === 'html'">
                                                <img [src]="asset?.preview_image ? asset?.preview_image : asset?.secondary_media[0]"
                                                    onerror="this.src='assets/images/default-nft.svg'"
                                                    (load)="imageLoading = false" alt="nft"
                                                    class="img-fluid tableviewnfts">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="request.collateral_assets.length === 5">
                                    <div class="mb-2 fourth_img">
                                        <div *ngFor="let asset of request.collateral_assets; let j=index;">
                                            <img *ngIf="j <= 8 && asset?.fileType != 'html'"
                                                [src]="asset?.preview_image ? asset?.preview_image : asset.primary_media"
                                                onerror="this.src='assets/images/default-nft.svg'"
                                                (load)="imageLoading = false" class="img-fluid">
                                            <div class="gemsView" *ngIf="j <= 8 && asset?.fileType === 'html'">
                                                <img [src]="asset?.preview_image ? asset?.preview_image : asset?.secondary_media[0]"
                                                    class="img-fluid tableviewnfts"
                                                    onerror="this.src='assets/images/default-nft.svg'"
                                                    (load)="imageLoading = false" alt="nft">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="request.collateral_assets.length === 6">
                                    <div class="mb-2 fifth_img">
                                        <div *ngFor="let asset of request.collateral_assets; let j=index;">
                                            <img *ngIf="j <= 8 && asset?.fileType != 'html'"
                                                [src]="asset?.preview_image ? asset?.preview_image : asset.primary_media"
                                                onerror="this.src='assets/images/default-nft.svg'"
                                                (load)="imageLoading = false" class="img-fluid">
                                            <div class="gemsView" *ngIf="j <= 8 && asset?.fileType === 'html'">
                                                <img [src]="asset?.preview_image ? asset?.preview_image : asset?.secondary_media[0]"
                                                    onerror="this.src='assets/images/default-nft.svg'"
                                                    (load)="imageLoading = false" alt="nft"
                                                    class="img-fluid tableviewnfts">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="request.collateral_assets.length > 6 ">
                                    <div class="mb-2 threeimages">
                                        <ng-container *ngFor="let asset of request.collateral_assets; let j=index;">
                                            <ng-container *ngIf="j <= (request.collateral_assets.length > 9 ? 7 : 8)">
                                                <div>
                                                    <img *ngIf="((request.collateral_assets.length > 9 && j <= 7) || (request.collateral_assets.length <= 9 && j <= 8)) && asset?.fileType === 'html'"
                                                        [src]="asset?.preview_image ? asset?.preview_image  : asset?.primary_media"
                                                        onerror="this.src='assets/images/default-nft.svg'"
                                                        (load)="imageLoading = false" class="img-fluid">
                                                    <div class="gemsView"
                                                        *ngIf="((request.collateral_assets.length > 9 && j <= 7) || (request.collateral_assets.length <= 9 && j <= 8)) && asset?.fileType === 'html'">
                                                        <img [src]="asset?.preview_image ? asset?.preview_image : asset?.secondary_media[0]"
                                                            class="img-fluid tableviewnfts"
                                                            onerror="this.src='assets/images/default-nft.svg'"
                                                            (load)="imageLoading = false" alt="nft">
                                                    </div>
                                                </div>

                                            </ng-container>
                                            <div *ngIf="request.collateral_assets.length > 9 && j === 8"
                                                class="text-center">+{{request.collateral_assets.length - 8}}</div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <div class="d-flex align-items-center justify-content-between gap-4">
                                    <div class="nft-details">
                                        <div class="position-relative">
                                            <div class="truncate" id="tooltiphead{{i}}">
                                                <h1 (window:resize)="setTooltipSize(i)" id="tooltiptitle{{i}}">
                                                    {{request.collateral_assets[0].name}}
                                                </h1>

                                            </div>
                                            <div class="tooltip" id="tooltipdescription{{i}}">
                                                {{request.collateral_assets[0].name}}</div>

                                        </div>
                                        <h2 *ngIf="request.status === 2">Repaid.</h2>
                                        <h2 *ngIf="request.status === 3">Foreclosed.</h2>
                                    </div>
                                </div>
                                <div class="noloan d-flex justify-content-between">
                                    <div>
                                        <h6 class="text-10">Loan request amount.</h6>
                                        <h5>{{Math.ceil(request.requested_loan_amount)| thousandSeparator}}
                                            {{request.currency_data.symbol}}</h5>
                                    </div>
                                    <div>
                                        <h6 class="text-right text-10">Annual interest.</h6>
                                        <h5 class="text-right">{{request.loan_percentage}}%</h5>
                                    </div>
                                </div>
                                <div class=" d-flex justify-content-between flex-wrap">
                                    <div>
                                        <h6 class="text-10">Duration.</h6>
                                        <h5>{{request.loan_duration_days}} Days.</h5>
                                    </div>
                                    <div>
                                        <h6 class="text-10 text-right">Appraisal value.</h6>
                                        <h5 class="text-right">{{request.appraisalValue | thousandSeparator}} USD</h5>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <!--grid view end-->

            <ng-container *ngIf="!account">
                <div class="col-md-6 mx-auto text-center">
                    <div class="not-found">
                        <div *ngIf="regulated">
                            <img src="assets/images/conncect-wallet.png" alt="connect wallet" class="img-fluid mb-4">
                            <h6>Login to view loan options.</h6>
                            <div class="text-center mt-4 mb-2">
                                <a class="onboards px-3 py-2" [routerLink]="['/sign-in']" (click)="setRoute()">Log
                                    in.</a>
                            </div>
                        </div>
                        <div *ngIf="!regulated">
                            <img src="assets/images/conncect-wallet.png" alt="connect wallet" class="img-fluid mb-4">
                            <h6>Connect wallet to view loan options.</h6>
                            <div class="text-center mt-4 mb-2">
                                <a class="onboards px-3 py-2" (click)="connectWallet()">Connect Wallet.</a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="account && !user">
                <div class="col-md-6 mx-auto text-center">
                    <div class="not-found">
                        <div>
                            <h6>Register to continue.</h6>
                            <div class="text-center mt-4 mb-2">
                                <a class="onboards px-3 py-2" [routerLink]="['/sign-up']"
                                    (click)="setRoute()">Register</a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!--table view-->
            <div class="tableview my-4" *ngIf="account && !isGridView && !loader &&  borrowingHistory.length > 0">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th width="9%">Item.</th>
                            <th>Item name.</th>
                            <th>Total Assets.</th>
                            <th>Collection.</th>
                            <th>Status.</th>
                            <th class="text-right pr-4">Amount.</th>
                            <th>Start date.</th>
                            <th>Due date.</th>
                            <th>APR(%).</th>
                        </thead>
                        <tbody>
                            <tr [routerLink]="['/borrow-detail', request._id]" [queryParams]="{regulated}"
                                class="cursor-pointer"
                                [ngClass]="{'repayed': request.status === 2, 'foreclosed': request.status === 3}"
                                *ngFor="let request of borrowingHistory">
                                <td>
                                    <div class="tableviewnft">
                                        <img *ngIf="request.collateral_assets[0]?.fileType != 'html'"
                                            [src]="request.collateral_assets[0].primary_media"
                                            onerror="this.src='assets/images/default-nft.svg'"
                                            (load)="imageLoading = false" class="img-fluid">
                                        <img *ngIf="request.collateral_assets[0]?.fileType === 'html'"
                                            [src]="request.collateral_assets[0]?.preview_image ? request.collateral_assets[0]?.preview_image : request.collateral_assets[0]?.secondary_media[0]"
                                            class="img-fluid" onerror="this.src='assets/images/default-nft.svg'"
                                            (load)="imageLoading = false" alt="nft">
                                    </div>
                                </td>
                                <td>
                                    <p class="text-break">{{request.collateral_assets[0].name}}</p>
                                </td>
                                <td>
                                    <p>{{request.collateral_assets.length}}</p>
                                </td>
                                <td>
                                    <p class="text-break">{{request.collateral_assets[0].collections.name}}</p>
                                </td>
                                <td>
                                    <p *ngIf="request.status === 2" class="repayed_status whitespace">Repaid.</p>
                                    <p *ngIf="request.status === 3" class="foreclosed_status whitespace">Foreclosed.</p>
                                </td>
                                <td>
                                    <p class="text-break whitespace text-right fw-5 pr-4">
                                        {{Math.ceil(request.requested_loan_amount) | thousandSeparator}}
                                        {{request.currency_data.symbol}}</p>

                                </td>
                                <td>
                                    <p class="whitespace"> {{request.start_date | date : 'dd/MM/YYYY'}} </p>
                                </td>
                                <td>
                                    <p class="whitespace"> {{request.end_date | date : 'dd/MM/YYYY'}} </p>
                                </td>
                                <td>
                                    <p class="whitespace text-right pr-4 fw-5"> {{request.loan_percentage}} </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!--end-->

            <ng-container *ngIf="!loader && borrowingHistory?.length == 0 && user">
                <div class="col-md-6 mx-auto text-center">
                    <div class="not-found">
                        <p>No borrowing history.</p>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="loader">
                <div class="col-md-10 mx-auto text-center">
                    <div class="not-found">
                        <p>Please wait while we load your items</p>&nbsp;
                        <span class="Loaders"></span>
                    </div>
                </div>
            </ng-container>
        </div>
    </section>
</div>