<div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header">
            <!-- <h1 class="modal-title fs-5" id="exampleModalLabel">Transaction Details.</h1> -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeTransactionModal()"
                aria-label="Close"></button>
        </div>
        <div class="modal-body ">
            <div class="bg-grey-option">
                <div class="transact-view">
                    <div class="walletaddress_tooltip" id="tooltip-head">
                        <h2>Contract call to {{transactionModalData.contractAddress}}.
                            <span class="cursor-pointer mx-2"><img
                                src="assets/images/copy-wallet.svg" alt="copy"
                                (click)="copyAddress(transactionModalData.contractAddress)">
                            </span>
                        </h2>
                    </div>
                </div>
                <div class="transact-card-details">
                    <h3>{{transactionModalData.operationName}}.</h3>
                    <div class="d-flex align-items-center justify-content-between mb-8">
                        <div class="d-flex align-items-start w-65">
                            <h5 class="approve" *ngIf="transactionModalData.operationStatus === 0">In Progress.</h5>
                            <i class="bi bi-info-circle-fill approve"
                                *ngIf="transactionModalData.operationStatus === 0"></i>

                            <h5 class="success" *ngIf="transactionModalData.operationStatus === 1">Success.</h5>
                            <i class="bi bi-info-circle-fill success"
                                *ngIf="transactionModalData.operationStatus === 1"></i>

                            <h5 class="in_balance" *ngIf="transactionModalData.operationStatus === 2">
                                {{transactionModalData.errorMessage}}.</h5>
                            <i class="bi bi-info-circle-fill in_balance"
                                *ngIf="transactionModalData.operationStatus === 2"></i>
                        </div>
                        <div>
                            <h6>{{transactionModalData.transactionTime | date: 'dd/MM/YYYY hh:mm:ss a'}}.</h6>
                        </div>
                    </div>
                    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0"
                        aria-valuemin="0" aria-valuemax="100" *ngIf="transactionModalData.operationStatus === 0">
                        <div class="progress-bar approve" style="width: 30%"></div>
                    </div>
                    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0"
                        aria-valuemin="0" aria-valuemax="100" *ngIf="transactionModalData.operationStatus === 1">
                        <div class="progress-bar success" style="width: 100%"></div>
                    </div>
                    <div class="user-detail">
                        <div class="w-100">
                            <h5>Interacted with (to).</h5>
                            <h6>{{transactionModalData.contractAddress}}.
                                <span class="cursor-pointer mx-2"><img
                                    src="assets/images/copy-wallet.svg" alt="copy"
                                    (click)="copyAddress(transactionModalData.contractAddress)">
                                </span>
                            </h6>
                        </div>
                        <div *ngIf="transactionModalData.transactionHash">
                            <h5>Tx hash.</h5>
                            <div class="d-flex align-items-center gap-2">
                                <p>{{transactionModalData.transactionHash.slice(0,
                                    8)}}...{{transactionModalData.transactionHash.slice(transactionModalData.transactionHash.length
                                    - 7)}}.</p>
                                <a target="_blank" href="{{transhUrl}}/tx/{{transactionModalData.transactionHash}}">
                                    <img src="assets/images/transacation-external.svg" alt="" width="18"
                                        height="18"></a>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="user-detail">
              <div>
                <h5>Vault</h5>
                <h6>pavithran@sparkouttech.com</h6>
              </div>
              <div>
                <h5>One Time Address</h5>
                <p>0x0eE4aC...0efE40b52</p>
              </div>
              <div>
                <a> <img src="assets/images/transacation-external.svg" alt="" width="18" height="18"></a>
              </div>
            </div> -->
                    <div class="nftitems">
                        <ng-container *ngFor="let nft of transactionModalData.nfts; let nftIndex = index">
                            <div *ngIf="nft?.fileType !== 'html'"
                                [ngClass]="{'transaction active': transactionModalData.activeNft._id === nft._id}"
                                (click)="setActiveNft(nftIndex)">
                                <img src="{{nft?.preview_image ? nft?.preview_image : nft.primary_media}}" alt="img"
                                    width="46" height="46" onerror="this.src='assets/images/default-nft.svg'"
                                    (load)="imageLoading = false">
                            </div>
                            <div *ngIf="nft?.fileType === 'html'" class="gemview-transacation"
                                [ngClass]="{'active': transactionModalData.activeNft._id === nft._id}"
                                (click)="setActiveNft(nftIndex)">
                                <img [src]="nft?.preview_image ? nft?.preview_image : nft.secondary_media[0] "
                                    width="46" height="46"
                                    [ngClass]="{'active': transactionModalData.activeNft._id === nft._id}"
                                    (click)="setActiveNft(nftIndex)" onerror="this.src='assets/images/default-nft.svg'"
                                    (load)="imageLoading = false">
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="more-details">
                <div class="d-flex align-items-start justify-content-between flex-wrap">
                    <h3>More details.</h3>
                    <!-- <a>Show on explorer</a> -->
                </div>
                <div class="asset-details">
                    <div class="first">
                        <h3>Asset.</h3>
                    </div>
                    <div class="second">
                        <h4>{{transactionModalData.activeNft.name}}.</h4>
                        <a [routerLink]="['/nft-detail', transactionModalData.activeNft?.collections?.collection_address, transactionModalData.activeNft?.token_id]"
                            [queryParams]="{ regulated: isRegulated }" target="_blank"><img
                                src="assets/images/transacation-external.svg"></a>
                    </div>
                </div>
                <!-- <div class="asset-details">
            <div class="first">
              <h3>Transaction Hash</h3>
            </div>
            <div class="second">
              <h4>{{transactionData?.transaction_hash}}
              </h4>
              <img src="assets/images/transacation-copy.svg">
            </div>

          </div> -->
                <!-- <div class="asset-details">
            <div class="first">
              <h3>Status</h3>
            </div>
            <div class="second">
              <div>
                <h4>Processing</h4>
                <p>The transaction reached the required number of confirmations on the
                  blockchain.</p>
              </div>
              <div>
                <img src="assets/images/transacation-copy.svg">
              </div>
            </div>
          </div> -->
                <!-- <div class="asset-details">
            <div class="first">
              <h3>Collection Address</h3>
            </div>
            <div class="second">
              <h4>Oxla82d7EC18DOb7f14fcA66CF348AF89293E2597E</h4>
              <div class="third">
                <img src="assets/images/transacation-copy.svg">
                <img src="assets/images/transacation-external.svg">
              </div>
            </div>

          </div> -->
                <div class="asset-details">
                    <div class="first">
                        <h3>Block number.</h3>
                    </div>
                    <div class="second">
                        <h4>{{txDetails.blockNumber}}.</h4>
                    </div>
                </div>
                <div class="asset-details">
                    <div class="first">
                        <h3>Gas price.</h3>
                    </div>
                    <div class="second">
                        <h4>{{txDetails.gasPrice}} MATIC.</h4>
                    </div>
                </div>
                <div class="asset-details">
                    <div class="first">
                        <h3>Last update.</h3>
                    </div>
                    <div class="second">
                        <h4>{{transactionModalData.transactionTime | date: 'dd/MM/YYYY hh:mm:ss a'}}.</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--end-->