<ng-container *ngIf="!account">
    <div class="col-md-6 mx-auto text-center">
        <div class="not-found">
            <!-- Regulated : Login -->
            <div *ngIf="regulated">
                <img src="assets/images/conncect-wallet.png" alt="connect wallet" class="img-fluid mb-4">
                <h6>Login to view {{titleText}}.</h6>
                <div class="text-center mt-4 mb-2">
                    <a class="onboards px-3 py-2" [routerLink]="['/sign-in']" (click)="setRoute()">Log
                        in.</a>
                </div>
            </div>
            <!-- Non Regulated : Connect Wallet -->
            <div *ngIf="!regulated">
                <img src="assets/images/conncect-wallet.png" alt="connect wallet" class="img-fluid mb-4">
                <h6>Connect wallet to view {{titleText}}.</h6>
                <div class="text-center mt-4 mb-2">
                    <a class="onboards px-3 py-2" (click)="connectWallet()">Connect Wallet.</a>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="account && !user">
    <div class="col-md-6 mx-auto text-center">
        <!-- Register to continue -->
        <div class="not-found">
            <div>
                <h6>Register to continue.</h6>
                <div class="text-center mt-4 mb-2">
                    <a class="onboards px-3 py-2" [routerLink]="['/sign-up']" (click)="setRoute()">Register.</a>
                </div>
            </div>
        </div>
    </div>
</ng-container>