import { Component, Input } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { WebStorageService } from '../../services/web-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-authentication-card',
  templateUrl: './user-authentication-card.component.html',
  styleUrls: ['./user-authentication-card.component.css'],
})
export class UserAuthenticationCardComponent {
  @Input() account: any;
  @Input() regulated!: boolean;
  @Input() user: any;
  @Input() titleText:string = ''

  constructor(private webStorageService: WebStorageService, private accountService: AccountService, private router: Router) {}

  /**
   * Asynchronously connects to the user's MetaMask wallet.
   * @async
   */
  async connectWallet() {
    await this.accountService.enableMetaMaskConnection(true);
  }

  /**
   * Sets the previous route in the web storage.
   * Stores the current router URL in the 'previousRoute' key of the web storage.
   */
  setRoute(): void {
    this.webStorageService.setItem('previousRoute', this.router.url);
  }
}
